import { Form, NavLink } from '@remix-run/react';
import {
  RiLogoutBoxLine,
  RiShieldUserLine,
  RiStarHalfLine,
  RiSurveyLine,
  RiTeamLine,
  RiUserSettingsLine,
} from '@remixicon/react';
import clsx from 'clsx';
import logo from '~/assets/logo.png';

function Sidebar() {
  return (
    <div className="hidden lg:flex h-dvh flex-col border-e border-stroke w-1/6 max-w-96 font-medium">
      <NavLink
        to="/"
        className="flex items-center justify-center mx-auto h-32 w-full border-b border-stroke">
        <img src={logo} alt="logo DDMA" className="h-20 object-cover" />
      </NavLink>
      <div className="flex flex-col gap-y-2 p-6 ">
        <NavLink
          to="/members"
          className={({ isActive }) =>
            clsx(
              'flex items-center gap-x-2 px-2 py-2.5',
              isActive
                ? 'text-primary bg-primary-yellow/10 border-s-4 border-primary-yellow'
                : 'text-typography-gray hover:bg-background'
            )
          }>
          <RiTeamLine size={20} />
          Leden
        </NavLink>
        <NavLink
          to="/questionnaires"
          className={({ isActive }) =>
            clsx(
              'flex items-center gap-x-2 px-2 py-2.5',
              isActive
                ? 'text-primary bg-primary-yellow/10 border-s-4 border-primary-yellow'
                : 'text-typography-gray hover:bg-background'
            )
          }>
          <RiSurveyLine size={20} />
          Vragenlijsten
        </NavLink>
        <NavLink
          to="/reviews"
          className={({ isActive }) =>
            clsx(
              'flex items-center gap-x-2 px-2 py-2.5',
              isActive
                ? 'text-primary bg-primary-yellow/10 border-s-4 border-primary-yellow'
                : 'text-typography-gray hover:bg-background'
            )
          }>
          <RiStarHalfLine size={20} />
          Beoordelingen
        </NavLink>
        <NavLink
          to="/ddma"
          className={({ isActive }) =>
            clsx(
              'flex items-center gap-x-2 px-2 py-2.5',
              isActive
                ? 'text-primary bg-primary-yellow/10 border-s-4 border-primary-yellow'
                : 'text-typography-gray hover:bg-background'
            )
          }>
          <RiUserSettingsLine size={20} />
          DDMA
        </NavLink>
        <NavLink
          to="/account/mfa"
          className={({ isActive }) =>
            clsx(
              'flex items-center gap-x-2 px-2 py-2.5',
              isActive
                ? 'text-primary bg-primary-yellow/10 border-s-4 border-primary-yellow'
                : 'text-typography-gray hover:bg-background'
            )
          }>
          <RiShieldUserLine size={20} />
          Account
        </NavLink>
      </div>
      <div className="flex flex-1 p-6 ">
        <div className="self-end w-full">
          <Form method="POST" action="/auth/logout">
            <button
              type="submit"
              className="flex items-center gap-x-2 px-2 py-2.5 text-typography-gray hover:bg-background w-full">
              <RiLogoutBoxLine size={18} />
              Uitloggen
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
